/* Import legacy allvoices preset */
@import "legacy-allvoices-preset";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .tailwind-layout {
    @apply bg-white-white text-foreground min-h-screen font-poppins;
    fontfeaturesettings: '"rlig" 1, "calt" 1';
  }

  * {
    @apply border-border;
  }

  /* Target elemnts with data-node-type */
  .AV_SHEET_DEBUGGING {
    [data-node-type] {
      &:before {
        @apply font-bold text-xs uppercase mb-1 inline-block absolute top-0 right-0 bg-red-coral z-10 !text-secondary-foreground;
        content: attr(data-node-type) "/" attr(data-node-path) "/" attr(data-node-id);
      }
      @apply relative p-5 border-2 border-red-coral;
    }
    [data-node-type] [data-node-type]:hover,
    [data-node-type="sheet"]:hover {
      &:before {
        @apply bg-gold-xanthous;
      }
      @apply border-gold-xanthous;
    }
  }

  .av-date-picker.react-datepicker-wrapper {
    @apply w-full;

    .react-datepicker__input-container {
      @apply w-full;
    }
  }

  *[data-is-resolved="true"] {
    @apply opacity-60 grayscale;
  }

  details > summary.list-none::-webkit-details-marker,
  details > summary.list-none::marker {
    display: none;
  }
}
